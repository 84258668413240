<template>
    <div class="loginView" v-loading="loading" element-loading-background="rgba(122, 122, 122, 0.5)">
        <div class="loginView-condom">
            <div class="loginView-condom_big">{{$t("login.tit2")}}</div>
            <div class="loginView-condom_small">{{$t("login.tit3")}}</div>
            <div class="loginView-main boxs1">
                <div class="loginView-main-logo">
                    <img src="../../assets/logo.jpg" alt="logo"/>
                </div>
                <el-form :model="forms" :rules="rules" ref='ruleFormRef'>
                    <el-form-item prop="names">
                        <el-input v-model="forms.names" type="text" :placeholder='$t("login.input1")'>
                            <template #prefix>
                                <i class="icon-gerentouxiang_o"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="passwords">
                        <el-input v-model="forms.passwords" :type="passward_" :placeholder='$t("login.input2")'>
                            <template #prefix>
                                <i class="icon-mima"></i>
                            </template>
                            <template #suffix>
                                <i @click="passward_ = passward_=== 'text'?'password':'text'" :class="passward_ === 'text'?
                                    'icon-yanjing_yincang_o active1':'icon-yanjing_xianshi_o active1'">
                                </i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="code">
                        <el-input v-model="forms.code" type="number"  :placeholder='$t("login.input3")'>
                            <template #prefix>
                                <i class="icon-miyue"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit(ruleFormRef)" style="width:100%">{{ $t("login.button") }}</el-button>
                    </el-form-item>
                </el-form>
                <div class="loginView-main-language">
                    <div class="write-centent">{{ $t("login.language") }}:</div>
                    <el-radio-group v-model="changeLanguage" @change="changeFun(e)">
                        <el-radio :label="key" size="small" v-for='(item,key) in languagesObj' :key='item'>{{item}}</el-radio>
                    </el-radio-group>
                </div>
            </div>
        </div>
        <enterView :names='forms.names' :access_token='access_token' ref='enterViewRef' @updatePassword='updatePassword'/>
    </div>
</template>
<script setup>
    import {reactive,ref,getCurrentInstance} from 'vue'
    import { languagesObj } from '@/config/language.js'
    import { configStore } from '@/store/config.js'
    import { changeLanguageFun } from '@/commonMethod/index.js'
    import { useI18n } from 'vue-i18n'
    import { useRouter } from 'vue-router';
    import api_ from "@/api/index"
    import { encryptions } from '@/utils/util'
    import { bindLoginInfo } from '@/commonMethod/index'
    import { ElMessage } from 'element-plus'
    import enterView from './enter.vue'
    import { questStore } from '@/store/quest.js'
    import { userStore } from '@/store/user'
    const { $t } = getCurrentInstance().proxy;

    const { locale } = useI18n()
    let router = useRouter()
    // const key_pri = ref('6Lc6szEjAAAAAEh9ZHaLV83yHsvastqQlZE5Orce')
    // const grecaptcha = ref(null);//dom
    const forms = reactive({
        names:'',
        passwords:'',
        code: '',
        randomStr: "blockPuzzle",
    })
    const loading = ref(false)

    const enterViewRef = ref(null)
    const passward_ = ref('password')
    const access_token = ref('')
    const changeLanguage = ref(configStore().getLange)
    const _this = getCurrentInstance().appContext.config.globalProperties;
    const rules = ref({
        names: [
            { required: true, message: $t("login.text1"), trigger: 'blur' },
        ],
        passwords: [
            { required: true, message: $t("login.text2"), trigger: 'blur' },
        ],
        code: [
            { required: true, message: $t("login.text3"), trigger: 'blur' },
        ],
    })
    const ruleFormRef = ref(null)


    const onSubmit = ()=>{
        // enterViewRef.value.init_()
       ruleFormRef.value.validate((formEl) => {
            if (!formEl) return
        loading.value = true
            api_.loginByUsername(forms.names,encryptions(forms.passwords),forms.code,forms.randomStr).then(res=>{
                loading.value = false
                if(res && res.access_token){
                    access_token.value = res.access_token
                    getUserInfo(res.access_token)
                }else{
                    ElMessage.warning(res.msg)
                }
            })
            _this.$router.push({path:'/main'});
        })
    }
    const changeFun = ()=>{
        let str_ = router.currentRoute.value.fullPath
        changeLanguageFun(changeLanguage.value,locale)
        _this.$router.push({path:str_.replace(str_.split(/\//)[1],changeLanguage.value)})
    }
    const updatePassword = ()=>{//成功修改密码
        bindLoginInfo(access_token.value)
        _this.$router.push({path:'/main'});
    }
    const getUserInfo = (access_token)=>{//获取用户信息
        api_.getUserInfo(forms.names,access_token).then(res=>{
            if(res.code === 0){
                questStore().setTenantId(res.data[0].tenantId)
                userStore().setUserInfo(res.data)
                // enterViewRef.value.init_()
                if(res.data[0].isFirstLogin === 1){//首次登录
                    enterViewRef.value.init_()
                }else{
                    updatePassword()
                }   
            }
        })
    }
</script>

<style lang='scss' scoped>
@import "@/assets/scss/parts.scss"; //变量样式

    .loginView{
        @include display_(center);
        background: url('../../assets/loginImg.png') no-repeat;background-size: 100% 100%;
        width: 100vw;height: 100vh;position: relative;

        &-condom{
            position: absolute;
            right: 2.4rem;
            width: 6rem;
            bottom: 1.4rem;
            display: flex;
            align-items: center;justify-content: center;flex-direction: column;
            color: #fff;
            &_big{
                font-size: .4rem;
                line-height: .4rem;
                margin-bottom: 10px;
            }
            &_small{
                font-size: 14px;
                margin-bottom: .65rem;
            }
        }
        &-main{
            width: 4.2rem;
            padding: .3rem;border-radius: .1rem;
            background: #fff;
            &-logo{
                width: 100%; @include display_(center);
                margin-bottom: 20px;
                img{
                    width: 120px;
                }
            }
            .write1{
                margin:16px 0;
            }
            .iconFont{
                font-size:20px
            }
            &-end{
                @include display_(centerA);
                margin-top: 30px;
                div{
                    margin-right: 10px;
                }
            }
            &-language{
                @include display_(centerA); 
                height:20px;
                margin-top: 10px;
                .write-centent{
                    margin-right:20px;
                }
                .el-radio{
                    margin-right: 12px;
                }
            }
        }
        .grecaptchas{
            margin-bottom: 10px;
        }
    }
</style>